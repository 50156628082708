<template>
  <div id="card-content">
    <v-card-title class="tw-flex tw-justify-center tw-items-center tw-text-sm">Add User to Queue</v-card-title>
      <v-card-text class="tw-pb-1">
        <div>
          <v-text-field
            :value="userSearch"
            placeholder="Search user"
            filled
            rounded
            height="30px"
            dense
            clearable
            prepend-inner-icon="mdi-magnify"
            @input="(x) => {
              doUpdateData(x, 'userSearch')  
              return debounceSearch();
            }"
          />
        </div>
        <div>
          <v-list subheader>
            <template v-if="!isLoading"> 
              <v-list-item
                v-for="(item, key) in items"
                :key="key"
                dense
                @click="() => doAddToQueue(item)"
              >
                <v-list-item-avatar>
                  <!-- <v-img v-if="item.avatar"
                    :alt="`${item.title} avatar`"
                    :src="item.avatar"
                  ></v-img> -->
                  <v-icon color="#000">
                    mdi-account-circle
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content class="tw-text-left">
                  <v-list-item-title>{{item.first_name ? item.first_name : '-'}} {{item.last_name ? item.last_name : '-'}} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-progress-linear 
              v-if="isLoading"
              indeterminate
              color="yellow darken-2"
            ></v-progress-linear>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="tw-text-xs tw-text-primary tw-cursor-pointer">Unregistered User</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
  </div>
</template>

<script>
import {
  debounce,
  // get
} from 'lodash'
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    userSearch: {
      type: String,
      default: '',
    },
    doUpdateData: {
      type: Function,
      default: () => {},
    },
    doSearchUser: {
      type: Function,
      default: () => {},
    },
    doAddToQueue: {
      type: Function,
      default: () => {},
    }
  },
  data (){
    return {}
  },
  methods: {
    debounceSearch: debounce(function () {
      this.doSearchUser();
    }, 1000)
  }
}
</script>

<style>

</style>